/* #root {
  min-height: 100vh;
} */
/* @import url("https://fonts.googleapis.com/css?family=Lato:"); */

.header {
  position: relative;
  padding: 10px;
}

.mt-2 {
  margin-top: 2rem;
}

.text-indent {
  text-indent: 20px;
}

img.logo {
  /* width: 35%; */
  width: 15%;
  margin: 4px 0px 0px 20px;
}

.bg-img {
  background-image: url("../src/images/bg-img-1.jpg");
  background-size: 100% 100%;
  /* background-size: cover; */
  background-repeat: "no-repeat";
}

.bgImageWrap {
  background-image: url("../src/images/bg-img.jpg");
  background-size: 100% 100%;
  /* background-size: cover; */
  background-repeat: "no-repeat";
}

.c-moto {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: 700;
  text-align: end;
}
.a-block {
  background: #1294f2;
  padding: 10px;
  /* border-radius: 8px; */
}

.a-block p {
  margin: 10px;
  text-align: justify;
  color: #fff;
  font-size: 18px;
}

.p-block {
  padding: 30px;
  text-align: justify;
}

.p-img {
  position: relative;
  display: block;
  width: 100%;
  /* height: 200px; */
}

/* img.u-logo {
  display: block;
  margin: 35px 10px AUTO;
  width: 87%;
  height: 80%;
  border-radius: 50%;
} */

img.u-logo {
  display: block;
  margin: auto;
  width: 20%;
  /* height: 100%; */
  border-radius: 50%;
}

img.content-img-block {
  display: block;
  margin: 0px AUTO;
  width: 50px;

  border: 2px solid #fff;
}

.p-outer-block {
  padding: 25px;
  text-align: justify;
  background-color: #f2f2f2;
}
.p-outer-block p {
  margin: 5px;
}

.p-outer-block .ml {
  margin: 25px 0px 0px 0px;
}

.p-info-block {
  border-radius: 8px;
  /* border: 4px solid grey; */
  /* box-shadow: 0px 0px 10px; */
  padding: 20px;
  background-color: #fff !important;
  text-align: left;
}

.c-block {
  position: relative;
  background-color: rgba(0, 0, 0, 0.4);
  box-shadow: 0px 0px 4px rgb(0 0 0 / 25%);
  border-radius: 17px;
  margin: 10% 0 2% 10%;
  width: 75%;
  padding: 10px;
}

.cp-block {
  position: relative;
  margin: 10px;
  color: #fff;
}

.cp-block p {
  font-size: 20px;
  text-align: center;
}

.sign {
  font-size: 35px;
  font-family: mistral;
  color: #1294f2;
}

.d-btn {
  display: flex !important;
  margin: 20px 0px 0px 0px;
  background-color: #1294f2 !important;
  text-transform: capitalize !important;
  text-decoration: none;
  width: 100px;
  justify-content: center;
  color: #fff;
  height: 26px;
  border-radius: 8px;
  padding: 5px;
}

.d-btn-md {
  appearance: none;
  background-color: #1294f2;
  border-radius: 40em;
  border-style: none;
  /* box-shadow: #1294f2 0 -12px 6px inset; */
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: block;
  font-size: 1.5rem;
  font-weight: 700;
  letter-spacing: -0.24px;
  margin: 10vh 0 0 20vw;
  outline: none;
  padding: 0.3rem 1rem;
  quotes: auto;
  text-align: center;
  text-decoration: none;
  transition: all 0.15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  text-transform: initial;
  height: 50px;
  width: 250px;
  text-transform: uppercase;
}

.comment-text-home-page {
  font-size: 30px;
}

/* .d-btn-md:hover {
  background-color: #ffc229;
  box-shadow: #ff6314 0 -6px 8px inset;
} */

.lowercase {
  text-transform: lowercase !important;
}

abbr {
  color: black;
  text-decoration: none;
  font-size: 9px;
}

.btn-gp {
  background: #fff;
}

.btn-gp button {
  font-size: 11px;
  text-transform: capitalize;
}

.btn-gp button:hover {
  background-color: #1294f2;
}

.tm-fltr-block {
  position: sticky;
  display: block;
  background: #fff !important;
  height: 230px;
  border-radius: 8px;
  overflow-y: scroll;
  /* margin-top: 30px; */
}
.tm-fltr-block button {
  color: black;
  display: flex;
  justify-content: center;
  margin: auto;
  line-height: 1rem;
  font-size: 10px;
}

.tm-fltr-block button:hover {
  background-color: #1294f2;
}

.react-calendar {
  height: 230px !important;
  border-radius: 8px;
}

.cp-block .react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  height: 28px;
}
.cp-block .react-calendar__navigation {
  margin-bottom: 0em !important;
  height: 24px !important;
}

.cp-block .react-calendar button {
  padding: 0;
}
.cp-block .react-calendar__navigation button {
  min-width: 20px !important;
  background: none;
}

.content-img-block {
  display: block;
  width: 100%;
  height: 100%;
}

.content-img-block img {
  width: 100%;
  height: 100%;
  /* 
  width: 35%;
  height: 100%;
  margin: 0 0 0 60%; */
}

.react-calendar__tile--active {
  background-color: #1294f2 !important;
}

.selected {
  background: cadetblue !important;
  color: #fff !important;
}
.selected:hover {
  background: cadetblue !important;
  color: #fff !important;
}

.f-sm {
  margin-top: -20px;
  margin-right: -6px;
  font-size: 6px;
  color: #000;
}

.content-img-block span {
  height: 50%;
}

.footer {
  /* margin: auto; */
  padding: 10px;
  font-size: 12px;
}

.outer-block {
  /* width: 100%; */
  /* padding: 0px 30px 0px 30px; */
  margin: 25px;
}

@media only screen and (min-width: 2560px) {
  .c-block {
    margin: 1% 0 2% 40%;
    width: 35%;
  }
  .d-btn-md {
    margin: 0 0 0 26vw;
  }
}

.bgImage {
  width: 100%;
  /* display: block; */
  height: 100%;
  position: relative;
}

.meetingContainer {
  /* position: absolute; */
  /* width: 100%; */
  margin: 25px;
  /* position: absolute; */
  /* top: 50%; */
  /* left: 50%; */
  /* transform: translate(-50%, -50%); */
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 17px;
  box-shadow: 0 0 4px rgb(0 0 0 / 25%);
  /* margin: 10% 0 2% 10%; */
  padding: 5px 20px;
  /* position: relative; */
  /* width: 75%; */
}

.bookMeeting {
  display: flex;
  flex-direction: row;
  width: 100%;
  /* margin-top: 1rem; */
  margin: 2rem 0rem;
}

.bookMeetingTittle {
  width: 45%;
  margin: auto;
  color: #000000;
  font-family: "Roboto", Sans-serif;
  font-size: 18px;
  font-weight: 800;
  margin-right: 1rem;
}

.bookMeetingImage {
  width: 100%;
  height: 100%;
  padding: 0.8rem;
  margin: auto;
  text-align: center;
  background-color: #1294f2;
  border-radius: 5px;
}

span.bookMeetingFont {
  color: #fff;
  /* font-weight: bold; */
  font-size: 18px;
}

a.atag {
  text-decoration: none;
}

.bgImageWrap {
  position: relative;
}

span.tittleStyle {
  color: #fff;
}

span.timeStyle {
  color: #fff;
  font-size: 16px;
}

span.bookMeetingFont {
}

@media only screen and (min-width: 2560px) {
  .c-block {
    margin: 1% 0 2% 40%;
    width: 35%;
  }
  .d-btn-md {
    margin: 0 0 0 26vw;
  }
}

@media only screen and (max-width: 959px) {
  .bgImage {
    height: 700px;
  }

  .meetingContainer1 {
    /* position: absolute; */
    width: 75%;
    margin: 20px auto;
    /* position: absolute; */
    /* top: 50%; */
    /* left: 50%; */
    /* transform: translate(-50%, -50%); */
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 15px;
    box-shadow: 0 0 4px rgb(0 0 0 / 25%);
    /* margin: 10% 0 2% 10%; */
    padding: 1px 25px;
    /* position: relative; */
    /* width: 75%; */
  }
}
